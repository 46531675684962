
import React, {useRef, useEffect} from "react";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";
import { Container, Row, Col } from "react-bootstrap";
import {fetch} from "window-or-global";
import fetchin from "../logic/utils/fetch";
import Manager from "../components/manager";
import Theme from "../components/theme";
import Button from "../components/bootstrap/button";
import FormsyTextField from "../components/formsy/text-field";
import config from "../config";
import Terms from "../components/tnc";
import root from "window-or-global";

const str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
export function generatePassword(s = str, len = 8) {
  let r = "";
  for (let i = 0; i < len; i++) {
    const idx = Math.round(getRandomArbitrary(0, s.length - 1));
    r += s[idx];
  }
  return r;
}


export default function LoginPage() {
  const [errorMessage, setErrorMessage] = useAsyncSetState(null);
  const [checked, setCheck] = useAsyncSetState(false);
  const [showTerm, setTerm] = useAsyncSetState(false);
  const [loginState, setLoginState] = useAsyncSetState(false);
  const [loading, setLoadingAsync] = useAsyncSetState(false);
  const authFormEl = useRef(null);
  const [creds, setCreds] = useAsyncSetState({
    username: "",
    password: "",
    dst: ""
  });
  const [authUrl, setAuthUrl] = useAsyncSetState(config.authUrl);
  useEffect(() => {
    try {
      if (root.location) {
        const url = new URL(root?.location);
        if (url.searchParams.has("src")) {
          const srcLocation = atob(url.searchParams.get("src"));
          const srcUri = new URL(srcLocation);
          setAuthUrl(`https://${srcUri.host}/login`)
          console.log("custom target set", `https://${srcUri.host}/login`);
        }
      }
    } catch(err) {
      console.log("failed to set custom auth url", err);
    }
  }, []);

  
  async function checkIfLoggedInOnHotspot() {
    try {
      const text = await fetchin.get(config.loginStatus);
      console.log("data", text);
      const data = JSON.parse(text);
      if (data) {
        if (data.loggedIn === "yes") {
          root.location = config.targetUrl;
        }
      }
    } catch(err) {
      console.log("unable to talk to the hotspot", err);
    }
  }
  
  async function handleValidSubmit(data) {
    try {
      await setLoadingAsync(true);

      const result = await fetchin.post(`${config.apiUrl}rest.api/login/`, {
        userName: data.userName,
        password: data.password,
      });

      const {Response, error} = result;
      switch(Response.status) {
        case 503:
          await setErrorMessage("An error has occurred");
          await setLoadingAsync(false);
          return;
        case  401:
          await setErrorMessage("Invalid username or password");
          await setLoadingAsync(false);
          return;
        case 200:

          await setLoadingAsync(false);
          await setCreds({userName: data.userName, password: data.password});
          authFormEl.current.submit();
          return;
        default:
          setErrorMessage("An unknown error has occurred");
          await setLoadingAsync(false);
          return;
      }
    } catch (err) {
      console.log("err", err);
      setLoadingAsync(false);
      setErrorMessage("An error has occurred");
    }
  }
  
  
  useEffect(() => {
    checkIfLoggedInOnHotspot();
  }, []);
  
  return (
    <Manager name="login" title="Register">
      <Theme>
        <form ref={authFormEl} action={config.authUrl} method="post">
          <input type="hidden" name="username" value={creds.userName} />
          <input type="hidden" name="password" value={creds.password} />
          <input type="hidden" name="dst" value={""} />
        </form>
        <Formsy onValidSubmit={(data) => handleValidSubmit(data)}>
          <Container fluid className="form-card-component form-spacer no-gutters">
            <div className="form-heading">
              <p className="form-heading-text">{"Login to your account"}</p>
            </div>
            <div className="field-row">
              <FormsyTextField
                name={"userName"}
                className="form-field-input"
                placeholder={'Your Username'}
                label={'Username'}
                required
              />
            
            </div>
            <div className="field-row">
              <FormsyTextField
                name={"password"}
                placeholder={'Your Password'}
                label={'Password'}
                className="form-field-input"
                type="password"
                required
              />
            </div>

            <div className="form-component-button">
              <Button
                type={"submit"}
                className={loading ? "continue-button-disable" : "continue-button-active"}
              >
                <span className="button-text">{"Continue"}</span> 
                <img
                  src="/images/arrow-right.svg"
                  className="button-icon pl-3"
                  alt="arrow-right"
                />
              </Button>
            </div>
          </Container>
        </Formsy>
      </Theme>
    </Manager>
  );
}
